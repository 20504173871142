import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import request from "../utils/request";
const MainLayout = ({ children }) => {
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await request.get("/userInfo"); // Adjust endpoint
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      } finally {
      }
    };

    fetchOffers();
  }, []);
  return (
    <div>
      <Header />
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
