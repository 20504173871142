import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ResponsiveContainer, AreaChart, Area } from "recharts";
import {
  Trash2,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ListFilter,
} from "lucide-react";
import { useWindowSize } from "../../utils";
import { SelectIconFilter, DashboarFilterIcon } from "../../assets/icon";
import DateRangePicker from "../DatePicker";
import SelectFilter from "./SelectFilter";
import { fetchData } from "../../utils/request";

const DashboardContainer = styled.div`
  width: 100%;
  height: ${(props) => props.size - 50}px;
  background-color: white;
  overflow-y: scroll;
  padding: 1rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #171a1f;
  margin-bottom: 8px;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
`;

const StatCard = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: ${(props) => props.bgColor};
  display: flex;
  align-items: center;
`;

const StatContent = styled.div`
  flex: 1;
`;

const StatTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #4b5563;
  margin-bottom: 8px;
`;

const StatValue = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 8px;
`;

const StatChange = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isPositive ? "#10B981" : "#EF4444")};
`;

const MiniChartContainer = styled.div`
  width: 100px;
  height: 50px;
`;

const TableContainer = styled.div`
  margin-bottom: 32px;
  width: 100%;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
`;

const Th = styled.th`
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
  font-weight: 600;
  color: #4b5563;
  white-space: nowrap;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const Td = styled.td`
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  white-space: nowrap;
`;

const TableCheckbox = styled.input`
  margin-right: 7px;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  background-color: ${(props) => {
    switch (props.status) {
      case "Новый":
        return "#3B82F6";
      case "В обработке":
        return "#F59E0B";
      case "Внесен депозит":
        return "#10B981";
      case "Закрыт":
        return "#6B7280";
      case "Отменен":
        return "#EF4444";
      default:
        return "#6B7280";
    }
  }};
`;

const ViewButton = styled.button`
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  background-color: #3b82f6;
  border: none;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
`;

const HeaderTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0px;
`;

const HeaderBottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
`;

const LeftSection = styled.div`
  display: flex;
  gap: 16px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SelectionInfo = styled.span`
  font-size: 14px;
  color: #2563eb;
`;

const IconWrapper = styled.div`
  color: #9ca3af;
  cursor: pointer;
`;

const PaginateWraper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledSelect = styled.select`
  appearance: none;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 36px 8px 36px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #5469d4;
  }
`;

const SelectIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const ArrowIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const IconWrapperSort = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ScrollableTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  max-width: ${(props) => props.size - 100}px;
`;

const Select = styled.div`
  position: relative;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const IconSelect = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const MiniChart = ({ data, color }) => (
  <ResponsiveContainer width="100%" height="170%">
    <AreaChart data={data}>
      <defs>
        <linearGradient
          id={`colorGradient-${color}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="1250%" stopColor={color} stopOpacity={0.1} />
          <stop offset="95%" stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="value"
        stroke={color}
        strokeWidth={2}
        fill={`url(#colorGradient-${color})`}
        fillOpacity={1}
      />
    </AreaChart>
  </ResponsiveContainer>
);

const TableRow = ({ click, isChecked, onCheckboxChange, row }) => (
  <Tr>
    <Td>
      <TableCheckbox
        type="checkbox"
        checked={isChecked}
        onChange={() => onCheckboxChange(row?.id)}
      />
      {row.id}
    </Td>
    <Td>{row?.created_at?.slice(0,10)}</Td>
    <Td>{row?.chat?.info?.first_name}</Td>
    <Td>
      <StatusBadge status={row?.status}>{row?.status}</StatusBadge>
    </Td>
    <Td>{row?.user?.name}</Td>
    <Td>{row?.link?.platform}</Td>
    <Td>{row?.buyer?.name}</Td>
    <Td>
      <ViewButton onClick={() => click(row?.id)}>Подробно</ViewButton>
    </Td>
  </Tr>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1); // Track the current page
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [hasMore, setHasMore] = useState(true); // Track if there's more data to load

  const [dateRange, setDateRange] = useState({
    start: new Date(2022, 6, 13),
    end: new Date(2022, 6, 20),
  });

  const miniChartData = [
    { name: "1", value: 100 },
    { name: "2", value: 120 },
    { name: "3", value: 110 },
    { name: "4", value: 140 },
    { name: "5", value: 130 },
    { name: "6", value: 160 },
    { name: "7", value: 170 },
  ];

  const handlerClick = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleOpenFilter = () => setIsFilterOpen(true);
  const handleCloseFilter = () => setIsFilterOpen(false);

  const handleSearch = (searchParams) => {
    console.log("Search with:", searchParams);
  };

  const CustomSelect = ({ value, onChange, options, icon: Icon }) => (
    <SelectWrapper>
      <StyledSelect value={value} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </StyledSelect>
      <SelectIcon>
        <SelectIconFilter />
      </SelectIcon>
      <ArrowIcon>
        <ChevronDown size={16} />
      </ArrowIcon>
    </SelectWrapper>
  );

  const loadMoreData = useCallback(async () => {
    try {
      const newData = await fetchData(page); // Use fetchData from request.js
      console.log("====================================");
      console.log(newData);
      console.log("====================================");
      if (newData.data.length === 0) {
        setHasMore(false); // No more data to load
      } else {
        setTableData(newData.data);
      }
    } catch (error) {
      console.error("Failed to load data:", error);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    loadMoreData();
  }, []);
  return (
    <DashboardContainer size={height}>
      <Header>
        <Title>Дашборд</Title>
        <DateRangePicker
          initialStartDate={dateRange.start}
          initialEndDate={dateRange.end}
          onApply={handleDateRangeChange}
        />
      </Header>

      <StatsGrid>
        <StatCard bgColor="#F1F2FD">
          <StatContent>
            <StatTitle>Общее количество заявок</StatTitle>
            <StatValue>164</StatValue>
            <StatChange isPositive>+15% с прошлой недели</StatChange>
          </StatContent>
          <MiniChartContainer>
            <MiniChart data={miniChartData} color="#1DD75B" />
          </MiniChartContainer>
        </StatCard>
        <StatCard bgColor="#FFF4F0">
          <StatContent>
            <StatTitle>Количество успешно обработанных заявок</StatTitle>
            <StatValue>159</StatValue>
            <StatChange isPositive>+32% с прошлой недели</StatChange>
          </StatContent>
          <MiniChartContainer>
            <MiniChart data={miniChartData} color="#1DD75B" />
          </MiniChartContainer>
        </StatCard>
        <StatCard bgColor="#FEFAEB">
          <StatContent>
            <StatTitle>Процент эффективности</StatTitle>
            <StatValue>5</StatValue>
            <StatChange>-54% с прошлой недели</StatChange>
          </StatContent>
          <MiniChartContainer>
            <MiniChart data={miniChartData} color="#DE3B40" />
          </MiniChartContainer>
        </StatCard>
        <StatCard bgColor="#FEFAEB">
          <StatContent>
            <StatTitle>Отмененные</StatTitle>
            <StatValue>5</StatValue>
            <StatChange>-54% с прошлой недели</StatChange>
          </StatContent>
          <MiniChartContainer>
            <MiniChart data={miniChartData} color="#DE3B40" />
          </MiniChartContainer>
        </StatCard>
      </StatsGrid>

      <TableContainer>
        <HeaderContainer>
          <HeaderTopSection>
            <div>
              <Title>Таблица заявок</Title>
            </div>
            <PaginateWraper>
              <span style={{ marginRight: 15 }}>1–6 из 29</span>
              <ChevronLeft
                size={20}
                color="#9095A0"
                cursor="pointer"
                style={{ marginRight: 10 }}
              />
              <ChevronRight size={20} color="#9095A0" cursor="pointer" />
            </PaginateWraper>
          </HeaderTopSection>
          <HeaderBottomSection>
            <LeftSection>
              <SelectionInfo>({selectedRows.length}) выбрано</SelectionInfo>
              <IconWrapper>
                <Trash2 size={20} />
              </IconWrapper>
            </LeftSection>
            <RightSection>
              {/* <IconWrapperSort>
                <DashboarFilterIcon />
              </IconWrapperSort> */}
              {/* <CustomSelect
                value={"Сортировать: Последнее обновление"}
                options={[
                  "Сортировать: Последнее обновление",
                  "Другая площадка",
                ]}
                icon={AlignLeft}
              /> */}
              <Select>
                <SelectButton onClick={handleOpenFilter}>
                  <IconSelect>
                    <ListFilter size={16} />
                  </IconSelect>
                  Сортировать: Последнее обновление <ChevronDown size={16} />
                </SelectButton>
              </Select>
              <SelectFilter
                isOpen={isFilterOpen}
                onClose={handleCloseFilter}
                onSearch={handleSearch}
              />
            </RightSection>
          </HeaderBottomSection>
        </HeaderContainer>
        <ScrollableTableWrapper size={width}>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Дата регистрации</Th>
                <Th>Оффер</Th>
                <Th>Статус</Th>
                <Th>Обработчик</Th>
                <Th>Площадка</Th>
                <Th>Баер</Th>
                <Th>Просмотр</Th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => (
                <TableRow
                  key={row.id}
                  row={row}
                  click={handlerClick}
                  isChecked={selectedRows.includes(row.id)}
                  onCheckboxChange={handleCheckboxChange}
                />
              ))}
            </tbody>
          </Table>
        </ScrollableTableWrapper>
      </TableContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
