import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ChevronDown,
  Plus,
  AlertCircle,
  Edit,
  User,
  Package,
  Globe,
  Layers,
  Archive,
} from "lucide-react";
import Modal from "./Modal";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request";
const DashboardContainer = styled.div`
  font-family: "Lato", sans-serif;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  padding: 3rem;
  overflow-y: scroll;
  height: 100vh;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Select = styled.div`
  position: relative;
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 150px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const SelectIcon = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const SelectOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
`;

const SelectOption = styled.div`
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;

  &:hover {
    background: #b8bcf6;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const ScrollableTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  max-width: ${(props) => props.size - 100}px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
`;

const TableHeader = styled.th`
  background: #f8f9fa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: #565e6c;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 12px;
  white-space: nowrap;
`;

const TableCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;
`;

const StatusSelect = styled(SelectButton)`
  width: 120px;
  background: #f3f4f6;
  border: none;
`;

const SelectStatus = styled.div`
  position: relative;
  width: 120px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Tr = styled.tr`
  white-space: nowrap;
  &:hover {
    background-color: #fff4f0;
  }
`;

const StatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background: #e5e7eb;
  }
`;

const StatusOptions = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 10;
`;

const StatusOption = styled.div`
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
`;

const Dashboard = () => {
  const { user } = SelectAuth();
  const { width } = useWindowSize();
  const [filters, setFilters] = useState({
    general: "Общий",
    user: "Антон пупкин",
    offer: "Оффер",
    platform: "Площадка",
    status: "Статус",
    archive: "Скрыть архивные",
    personal: "Личный",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [updateTask, setUpdateTask] = useState(null);
  const [openStatusIndex, setOpenStatusIndex] = useState(null);
  const [createTitleMd, setCreateTitleMd] = useState("Добавить залив");
  const [offers, setOffers] = useState([]);
  const [distribute, setDistribute] = useState([]);
  const [offerId, setOfferId] = useState([]);
  // Fetch existing data if in edit mode
  const handleSelectClick = (filterName) => {
    setOpenSelect(openSelect === filterName ? null : filterName);
  };

  const handleOptionSelect = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
    setOpenSelect(null);
  };


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response?.data);
        const dist = await request.get("/base-expenses"); // Replace with your actual API endpoint
        setDistribute(dist?.data);
        const offer = await request.get("/offers"); // Replace with your actual API endpoint
        setOfferId(offer?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
      }
    };

    fetchCategories();
  }, []);
  // Fetch tasks data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await request.get("/flood", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTasks(response.data);
        setStatuses(response.data.map((task) => task?.status));
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };
    !isModalOpen && fetchData();
  }, [isModalOpen]);

  // Handle status click and toggle dropdown
  const handleStatusClick = (index) => {
    setOpenStatusIndex(openStatusIndex === index ? null : index);
  };

  // Handle status selection and update task status via API
  const handleStatusSelect = async (index, value) => {
    const updatedStatuses = [...statuses];
    updatedStatuses[index] = value;

    // Optimistically update the UI
    setStatuses(updatedStatuses);
    setOpenStatusIndex(null);

    // Make an API call to update the status
    try {
      const token = localStorage.getItem("token");
      const taskId = tasks[index].id; // Assuming tasks[index] has an `id`
      await request.put(
        `/tasks/${taskId}`,
        { status: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Status updated successfully!");
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  // Modal handling for adding and editing tasks
  const CreateDown = (title) => {
    setIsModalOpen(true);
    setCreateTitleMd(title);
  };
  const renderSelect = (filterName, icon, dataSelect = []) => (
    <Select>
      <SelectButton onClick={() => handleSelectClick(filterName)}>
        <SelectIcon>{icon}</SelectIcon>
        {filters[filterName]} <ChevronDown size={16} />
      </SelectButton>
      {openSelect === filterName && (
        <SelectOptions>
          {dataSelect.map((item) => (
            <SelectOption onClick={() => handleOptionSelect(filterName, item)}>
              {item}
            </SelectOption>
          ))}
        </SelectOptions>
      )}
    </Select>
  );

  return (
    <DashboardContainer>
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={createTitleMd}
        taskId={updateTask}
      />
      <TopRow>
        <AddButton onClick={() => CreateDown("Добавить залив")}>
          <Plus size={16} />
          Залив
        </AddButton>
        <FiltersContainer>
          {["owner"].includes(user?.role) && (
            <>
              {renderSelect("general", <Layers size={16} />, [
                "Общий",
                "По баерам",
                "По тимлидам",
              ])}
              {renderSelect("user", <User size={16} />)}
            </>
          )}
          {["team_lead"].includes(user?.role) &&
            renderSelect("personal", <User size={16} />, [
              "Личный",
              "Моя команда",
            ])}
          {renderSelect("offer", <Package size={16} />)}
          {renderSelect("platform", <Globe size={16} />, [
            "Google",
            "Facebook",
            "Twitter",
            "Telegram",
            "Vk",
            "Youtube",
            "TikTok",
            "Instagram",
          ])}
          {renderSelect("status", <Layers size={16} />, [
            "Активный",
            "Не активный",
            "Архивировать",
          ])}
          {renderSelect("archive", <Archive size={16} />)}
        </FiltersContainer>
      </TopRow>
      <TableContainer>
        <ScrollableTableWrapper size={width}>
          <Table>
            <thead>
              <tr>
                <TableHeader>Мастер</TableHeader>
                <TableHeader>Название</TableHeader>
                <TableHeader>Оффер</TableHeader>
                <TableHeader>База расходников</TableHeader>
                {/* <TableHeader>Статус</TableHeader> */}
                <TableHeader>Затраты</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, index) => (
                <Tr key={task?.id}>
                  <TableCell>{task?.master}</TableCell>
                  <TableCell>{task?.title}</TableCell>
                  <TableCell>
                    {/* {offerId.map(
                      (item) => item.id == task?.offer_id && item.offer_name
                    )} */}
                    {task?.offer_id}
                  </TableCell>
                  <TableCell>
                    {distribute?.map(
                      (item) =>
                        item.id == task?.fields[0]?.base_expense_id &&
                        item.title
                    )}
                  </TableCell>
                  {/* <TableCell>
                    <SelectStatus>
                      <StatusButton onClick={() => handleStatusClick(index)}>
                        {statuses[index]} <ChevronDown size={16} />
                      </StatusButton>
                      {openStatusIndex === index && (
                        <StatusOptions>
                          <StatusOption
                            onClick={() =>
                              handleStatusSelect(index, "Активный")
                            }
                          >
                            Активный
                          </StatusOption>
                          <StatusOption
                            onClick={() =>
                              handleStatusSelect(index, "Не активный")
                            }
                          >
                            Не активный
                          </StatusOption>
                          <StatusOption
                            onClick={() =>
                              handleStatusSelect(index, "Архивировать")
                            }
                          >
                            Архивировать
                          </StatusOption>
                        </StatusOptions>
                      )}
                    </SelectStatus>
                  </TableCell> */}
                  <TableCell>{task?.cost}$</TableCell>
                  <TableCell>
                    <IconContainer>
                      <AlertCircle size={20} color="#888" cursor={"pointer"} />
                      <Edit
                        size={20}
                        color="#379AE6"
                        cursor={"pointer"}
                        onClick={() => {
                          setUpdateTask(task?.id);
                          CreateDown("Редактировать залив");
                        }}
                      />
                    </IconContainer>
                  </TableCell>
                </Tr>
              ))}
            </tbody>
          </Table>
        </ScrollableTableWrapper>
      </TableContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
