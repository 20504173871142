import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import AvatarSettings from "../../assets/image/AvatarSettings.png";
import { IconPlus } from "../../assets/icon";
import Modal from "./Modal";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request, { base_url, file_url } from "../../utils/request";
import axios from "axios";
const MainContainer = styled.div`
  display: flex;
  width: 100%;
  // min-height: 100vh;
  background: #ffffff;
  font-family: "Arial", sans-serif;

  overflow-y: auto;
  // height: 100vh;
  height: ${(props) => props.size - 70}px;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const Sidebar = styled.div`
  width: 240px;
  padding: 20px;
  border-right: 1px solid #e9ecef;
`;

const TabButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: left;
  background: ${(props) => (props.active ? "#e7e9fd" : "transparent")};
  border: none;
  border-radius: 4px;
  color: ${(props) => (props.active ? "#3f51b5" : "#495057")};
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.active ? "#e7e9fd" : "#f1f3f5")};
  }
`;

const ContentArea = styled.div`
  flex-grow: 1;
  padding: 16px 35px;
  // margin-bottom: 900px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #212529;
  margin-bottom: 30px;
`;

const Form = styled.form`
  max-width: 500px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  color: #495057;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? "#dc3545" : "#ced4da")};
  border-radius: 4px;
  background-color: #f8f9fa;

  &:focus {
    outline: none;
    border-color: ${(props) => (props.error ? "#dc3545" : "#80bdff")};
    box-shadow: 0 0 0 0.2rem
      ${(props) =>
        props.error ? "rgba(220,53,69,.25)" : "rgba(0,123,255,.25)"};
  }
`;

const ErrorMessage = styled.span`
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
  display: block;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #5a51d1;
  }

  &:disabled {
    background-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const AddPhotoButton = styled.label`
  padding: 10px 20px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 3rem;

  &:hover {
    background-color: #5a51d1;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-left: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? "#dc3545" : "#ced4da")};
  border-radius: 4px;
  background-color: #f8f9fa;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;

  &:focus {
    outline: none;
    border-color: ${(props) => (props.error ? "#dc3545" : "#80bdff")};
    box-shadow: 0 0 0 0.2rem
      ${(props) =>
        props.error ? "rgba(220,53,69,.25)" : "rgba(0,123,255,.25)"};
  }
`;

const NotificationContainer = styled.div`
  max-width: 800px;
`;

const NotificationTitle = styled.h2`
  font-size: 24px;
  color: #212529;
  margin-bottom: 20px;
`;

const NotificationSetting = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const NotificationLabel = styled.span`
  font-size: 16px;
  color: #495057;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  margin-left: 62px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #6c63ff;
  }

  &:checked + span:before {
    transform: translateX(26px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #5a51d1;
  }
`;

const Table = styled.table`
  width: 1306px;
  margin: 29px 0 0 0px;
  border-collapse: collapse;
  margin-bottom: 5rem;
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;

  &:hover {
    background: #b8bcf6;
  }
`;

const NotificationSettings = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const handleToggle = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  const handleSave = () => {
    console.log("Настройки уведомлений сохранены:", {
      enabled: notificationsEnabled,
    });
  };

  return (
    <NotificationContainer>
      <NotificationTitle>Настройка уведомлений</NotificationTitle>
      <NotificationSetting>
        <NotificationLabel>Включить уведомления</NotificationLabel>
        <ToggleSwitch>
          <ToggleInput
            type="checkbox"
            checked={notificationsEnabled}
            onChange={handleToggle}
          />
          <ToggleSlider />
        </ToggleSwitch>
      </NotificationSetting>
      <SaveButton onClick={handleSave}>Сохранить</SaveButton>
    </NotificationContainer>
  );
};

const TelegramSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [telegram, setTelegram] = useState([]);
  const fetchUser = async () => {
    try {
      const response = await request.get("/accounts");
      setTelegram(response.data.data);
    } catch (err) {
      setError("Failed to fetch ");
    }
  };
  useEffect(() => {
    fetchUser();
  }, [isModalOpen]);
  const handleToggle = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  const removeObjectById = async (index) => {
    try {
      await request.post(`/account/${index}`); // API call to delete
      fetchUser();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <NotificationContainer>
      <NotificationTitle>Добавить телеграм</NotificationTitle>
      <AddButton onClick={() => setIsModalOpen(true)}>
        Добавить <IconPlus />
      </AddButton>
      <NotificationSetting>
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>Номер Телефона</Th>
              <Th>APP ID</Th>
              <Th>APP Hash key</Th>
              <Th>Действия</Th>
            </tr>
          </thead>
          <tbody>
            {telegram.map((offer, index) => (
              <Tr key={index}>
                <Td>{offer.id}</Td>
                <Td>{offer.phone_number}</Td>
                <Td>{offer.api_id}</Td>
                <Td>{offer.api_hash}</Td>
                <Td>
                  <ActionButton onClick={() => window.confirm('Вы хотите удалить?') && removeObjectById(offer.id)}>
                    Удалить
                  </ActionButton>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </NotificationSetting>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setTelegram={setTelegram}
      />
    </NotificationContainer>
  );
};

const Settings = () => {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const [activeTab, setActiveTab] = useState("profile");
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({});
  const [avatarSrc, setAvatarSrc] = useState(
    "https://cdn-icons-png.flaticon.com/256/149/149071.png"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await request.get("/profile/info");
        setInfo(response.data);
        setAvatarSrc(file_url + response?.data?.image);
        // setNotifications(response.data); // Assuming API returns notifications in response.data
      } catch (err) {
        setError("Failed to fetch ");
      }
    };

    fetchUser();
  }, []);

  // Function to handle saving profile data
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token"); // Assuming you're storing the token
      const formData = new FormData();

      // Add form data
      formData.append("name", data.name);
      formData.append("telegram", data.telegram);
      formData.append("phone", data.phone);

      formData.append("email", data.email);
      formData.append("password", data.password);
      // Add avatar if it was changed
      if (data.image.length > 0) {
        formData.append("image", data.image[0]);
      }

      // Send API request to update the profile
      const response = await axios.post(base_url + "/profile/edit", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setAvatarSrc(imageUrl);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return (
          <>
            <Title>Профиль</Title>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <AvatarContainer>
                  <Avatar src={avatarSrc} alt="Аватар пользователя" />
                  <AddPhotoButton htmlFor="photo-upload">
                    Добавить фото
                  </AddPhotoButton>
                  <HiddenFileInput
                    id="photo-upload"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    name="image"
                    {...register("image")}
                  />
                </AvatarContainer>
              </FormGroup>
              <FormGroup>
                <Label>Имя</Label>
                <Input
                  {...register("name", {
                    required: "Это поле обязательно",
                  })}
                  defaultValue={info?.name || ""}
                  error={errors.firstName}
                />
                {errors.firstName && (
                  <ErrorMessage>{errors.firstName.message}</ErrorMessage>
                )}
              </FormGroup>
              {/* <FormGroup>
                <Label>Фамилия</Label>
                <Input
                  {...register("lastName", {
                    required: "Это поле обязательно",
                  })}
                  defaultValue="Hill"
                  error={errors.lastName}
                />
                {errors.lastName && (
                  <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Отчество</Label>
                <Input {...register("middleName")} defaultValue="Lorem" />
              </FormGroup> */}
              <FormGroup>
                <Label>Telegram</Label>
                <Input
                  {...register("telegram")}
                  defaultValue={info?.telegram || ""}
                />
              </FormGroup>
              <FormGroup>
                <Label>Номер телефона</Label>
                <Input
                  {...register("phone", {
                    required: "Это поле обязательно",
                    pattern: {
                      value: /^\+?[0-9\s-()]{10,20}$/,
                      message: "Некорректный номер телефона",
                    },
                  })}
                  defaultValue={info?.phone || ""}
                  error={errors.phone}
                />
                {errors.phone && (
                  <ErrorMessage>{errors.phone.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label>E-mail</Label>
                <Input
                  {...register("email", {
                    required: "Это поле обязательно",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Некорректный email адрес",
                    },
                  })}
                  defaultValue={info?.email || ""}
                  error={errors.email}
                />
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Пароль</Label>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Это поле обязательно",
                    minLength: {
                      value: 8,
                      message: "Пароль должен содержать минимум 8 символов",
                    },
                  })}
                  placeholder="••••••••••••"
                  error={errors.password}
                />
                {errors.password && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </FormGroup>
              {/* <FormGroup>
                <Label>Ваша роль</Label>
                <Select
                  {...register("role", { required: "Выберите вашу роль" })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Выберите роль
                  </option>
                  <option value="owner">Обработчик</option>
                  <option value="manager">Баер</option>
                  <option value="buyer">Тим лид</option>
                  <option value="team_lead">Овнер</option>
                </Select>
                {errors.role && (
                  <ErrorMessage>{errors.role.message}</ErrorMessage>
                )}
              </FormGroup> */}
              <SubmitButton type="submit" disabled={isLoading}>
                Сохранить
                {isLoading && <Loader />}
              </SubmitButton>
            </Form>
          </>
        );
      // Other cases...
      case "notifications":
        return <NotificationSettings />;
      case "telegram":
        return <TelegramSettings />;
      default:
        return null;
    }
  };

  return (
    <MainContainer size={height}>
      <Sidebar>
        <TabButton
          active={activeTab === "notifications"}
          onClick={() => setActiveTab("notifications")}
        >
          Уведомления
        </TabButton>
        <TabButton
          active={activeTab === "profile"}
          onClick={() => setActiveTab("profile")}
        >
          Профиль
        </TabButton>
        {["owner"].includes(user?.role) && (
          <TabButton
            active={activeTab === "telegram"}
            onClick={() => setActiveTab("telegram")}
          >
            Добавить телеграм аккаунт
          </TabButton>
        )}
      </Sidebar>
      <ContentArea>{renderContent()}</ContentArea>
    </MainContainer>
  );
};

export default Settings;
